<template>
  <v-app id="inspire" class="horizontalstyle">
    <vertical-header :user="user" />
    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>
    <vertical-sidebar />
    <base-loading />
    <base-download />
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VerticalSidebar from './sidebar/VerticalSidebar.vue'
import VerticalHeader from './header/VerticalHeader.vue'

export default {
  components: {
    VerticalSidebar,
    VerticalHeader
  },
  name: 'MainLayout',
  props: {
    source: String
  },
  data: () => ({
    expandOnHover: false,
    firstAccess: false,
    textMessage: '',
    title: '',
    id: ''
  }),
  computed: {
    ...mapState(['Customizer_drawer', 'setHorizontalLayout', 'user'])
  },

  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    if (!this.$vuetify.breakpoint.xs) {
      this.firstAccess = true
    }
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER'
    }),
    logout () {
      this.$store.dispatch('setDataAuth', {})
      this.$store.dispatch('organization/clearOrganization')
      this.$axios.defaults.headers.common.Authorization = ''
      this.$router.push({ path: '/login' })
      localStorage.clear()
    }
  }
}
</script>
