<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list expand nav class="mt-1">
      <template v-for="(item, i) in itemsComputed">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">
              {{
              item.header
              }}
            </v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <base-item-group v-else-if="item.children" :key="`group-${i}`" :item="item"></base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import VerticalSidebarItems from './VerticalSidebarItems'
import EditoraLt from '../../../utils/editoraLt'
import collect from 'collect.js'

export default {
  name: 'VerticalSidebar',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: VerticalSidebarItems
  }),
  computed: {
    ...mapState(['SidebarColor', 'SidebarBg']),
    Sidebar_drawer: {
      get () {
        return this.$store.state.Sidebar_drawer
      },
      set (val) {
        this.$store.commit('SET_SIDEBAR_DRAWER', val)
      }
    },
    getDomains () {
      return this.$store.getters['organization/getActiveOrganizationDomains'] || []
    },
    itemsComputed () {
      const lt = new EditoraLt()
      const condition = lt.activeOrganizationHasDomain(this.getDomains)
      return collect(this.items)
        .where('name', '!=', 'Material de apoio')
        .when(condition, items => items.push({
          title: 'Material de apoio',
          icon: 'mdi-book-multiple-outline',
          to: '/support-material'
        }))
        .all()
    }
  },
  watch: {
    '$vuetify.breakpoint.smAndDown': function (val) {
      this.$emit('update:expandOnHover', !val)
    }
  },

  methods: {}
}
</script>
<style >
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}
#main-sidebar .v-navigation-drawer__border {
  display: none;
}
#main-sidebar .v-list {
  padding: 8px 15px;
}
#main-sidebar .v-list-item__icon--text, #main-sidebar .v-list-item__icon:first-child {
  justify-content: center;
  text-align: center;
  width: 20px;
}

</style>
