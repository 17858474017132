const VerticalSidebarItems = [
  {
    title: 'Instituição',
    icon: 'mdi-home-city-outline',
    to: '/organization'
  },
  {
    title: 'Avaliações',
    icon: 'mdi-pencil-box-multiple-outline',
    to: '/resolution'
  }
]

export default VerticalSidebarItems
