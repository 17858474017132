<template>
  <v-app-bar
    app
    flat
    clipped-left
    clipped-right
    :color="navbarColor"
    :dark="navbarColor !== 'white'"
  >
    <v-toolbar-title class="align-center d-flex logo-width" v-if="navbarColor !== 'white'">
      <span class="logo-icon">
        <img src="@/assets/images/logo/logo-pruvo-icon.png" class="mt-2" />
      </span>
      <span class="logo-text ml-2 d-none d-md-block">
        <img src="@/assets/images/logo/logo-pruvo-text.png" class="mt-2" />
      </span>
    </v-toolbar-title>
    <!---/Toggle sidebar part -->
    <div class="d-md-none">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>
    <!---/Toggle sidebar part -->

    <!---Search part -->
    <!-- <v-btn dark icon class="mr-1 d-sm-block d-none" @click="searchbox">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-card v-if="showSearch" color="primary" class="searchinput rounded-0">
      <template>
        <v-text-field
          placeholder="Digite e pressione enter"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          dark
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </v-card>-->
    <!---/Search part -->

    <v-spacer />
    <!---right part -->

    <!---Messages -->
    <!-- <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-2">
          <v-badge color="red" dot>
            <v-icon>mdi-message</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 pt-2 py-3 font-weight-medium title">Mensagens</h4>
        <v-divider></v-divider>
        <v-list-item v-for="(item, i) in messages" :key="i" @click="href">
          <v-list-item-title>
            <div class="d-flex align-center py-3">
              <div class="mr-3">
                <v-badge bordered bottom :color="item.avatarstatus" dot offset-x="10" offset-y="10">
                  <v-avatar>
                    <img src :alt="item.title" />
                  </v-avatar>
                </v-badge>
              </div>
              <div>
                <h4 class="font-weight-medium">{{ item.title }}</h4>
                <span class="text--secondary caption descpart d-block text-truncate">{{ item.desc }}</span>
                <small class="text--secondary">{{ item.time }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    -Messages
    </v-menu>-->

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-badge color="red" dot v-if="notifications && notifications[0]">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <template v-else>
            <v-icon>mdi-bell</v-icon>
          </template>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Notificações</h4>
        <v-divider></v-divider>
        <template v-if="notifications && notifications.length">
          <v-list-item v-for="(item, i) in notifications" :key="i" @click="href(item.url)">
            <v-list-item-title>
              <div class="d-flex align-center py-3">
                <div class>
                  <v-btn class="mr-3" depressed fab small dark :color="item.iconbg">
                    <v-icon dark>{{ item.icon }}</v-icon>
                  </v-btn>
                </div>
                <div>
                  <h4 class="font-weight-medium">{{ item.title }}</h4>
                  <span
                    class="text--secondary caption descpart d-block text-truncate"
                  >{{ item.desc }}</span>
                  <small class="text--secondary">{{ item.time }}</small>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item>
            <v-list-item-title>
              <div>
                <!-- <h4 class="font-weight-medium"></h4> -->
                <span
                  class="text--secondary caption descpart d-block text-truncate"
                >Nenhuma notificação</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <!-- <small class="text--secondary">{{ item.time }}</small>-->
        </template>
      </v-list>
    </v-menu>
    <!---Notification -->

    <!---User -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40" color="#fff">
            <img v-if="user.me && user.me.data.avatar" :src="user.me.data.avatar" />
            <v-icon v-else color="primary">mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          :href="item.href"
          :to="item.to"
          @click="action(item.action)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default () {
        return {
          me: {
            avatar: null,
            email: '',
            name: '',
            id: ''
          }
        }
      }
    }
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    notifications: [
      // {
      //   title: 'Nova Prova',
      //   iconbg: 'success',
      //   icon: 'mdi-check',
      //   desc: 'Turma: 9o Ano Matemática - Prova: Sinais e Sistemas',
      //   time: '01/01/2021 9:30'
      // },
      // {
      //   title: 'Prova Cancelada',
      //   iconbg: 'error',
      //   icon: 'mdi-close',
      //   desc: 'Turma: 9o Ano Matemática - Prova: Sinais e Sistemas',
      //   time: '01/01/2021 9:30'
      // },
      // {
      //   title: 'Prova Alterada',
      //   iconbg: 'warning',
      //   icon: 'mdi-alert-outline',
      //   desc: 'Turma: 9o Ano Matemática - Prova: Sinais e Sistemas',
      //   time: '01/01/2021 9:30'
      // }
    ],
    messages: [
      {
        title: 'José da Silva',
        avatar: '1',
        avatarstatus: 'success',
        desc: 'Olá John. Poderia verificar a sua nota no sistema.',
        time: '01/01/2021 9:30'
      }
    ],
    userprofile: [
      // { title: 'Minha Conta', to: '/apps/profile' },
      { title: 'Sair', action: 'logout' }
    ],
    href (link) {
      window.open(link, '_blank')
      return undefined
    }
  }),

  computed: {
    ...mapState(['navbarColor', 'Sidebar_drawer'])
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'SET_SIDEBAR_DRAWER'
    }),
    action (key) {
      switch (key) {
        case 'logout':
          this.logout()
          break

        default:
          break
      }
    },
    logout () {
      this.$store.dispatch('setDataAuth', {})
      this.$store.dispatch('organization/clearOrganization')
      this.$axios.defaults.headers.Authorization = null
      this.$axios.defaults.headers.common.Authorization = null
      this.$router.push({ path: '/login' })
      localStorage.clear()
    },
    searchbox () {
      this.showSearch = !this.showSearch
    }
  },

  mounted () {
    if (this.$vuetify.breakpoint.mobile) {
      this.notifications.push(
        {
          title: 'Acesse pelo aplicativo',
          iconbg: 'info',
          icon: 'mdi-google-play',
          desc: 'Acesse Pruvo pelo aplicativo para celulares',
          url: 'https://play.google.com/store/apps/details?id=app.pruvo.aluno.twa'
        }
      )
    }
  }
}

</script>

<style>
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
